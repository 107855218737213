import { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// pages
const Home = lazy(() => import("./pages/Home"));
const Contact = lazy(() => import("./pages/Contact.js"));
const Management = lazy(() => import("./pages/Management.js"));
const Videos = lazy(() => import("./pages/Videos.js"));
const GreatKids = lazy(() => import("./pages/GreatKids.js"));
const Circles = lazy(() => import("./pages/Circles.js"));
const News = lazy(() => import("./pages/News.js"));
const Photos = lazy(() => import("./pages/Photos.js"));
const NewsInner = lazy(() => import("./pages/news/[slug].js"));

function App() {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/management' element={<Management />} />
          <Route path='/videos' element={<Videos />} />
          <Route path='/great-kids' element={<GreatKids />} />
          <Route path='/circles' element={<Circles />} />
          <Route path='/news' element={<News />} />
          <Route path='/photos' element={<Photos />} />
          <Route path='/news/:slug' element={<NewsInner />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
